.gameDonnut-content {
  display: flex;
  height: 80vh;
  margin-top: 50px;
}

.sidebarLeft {
  width: 22.5%;
  background-color: #090a1a;
  gap: 20px;
  padding: 10px;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 15px;
}

.sidebarLeftSmall {
  width: 70%;
  background-color: #090a1a;
  gap: 20px;
  padding: 10px;
  opacity: 90%;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 15px;
}

.sidebarRight {
  width: 22.5%;
  background-color: #090a1a;
  padding: 10px;
  opacity: 90%;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 15px;
}

.sidebarRight1 {
  background-color: #090a1a;
  padding: 20px;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 15px;
}

.sidebarRight2 {
  background-color: #090a1a;
  padding: 20px;
  opacity: 90%;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 15px;
}

.content {
  width: 60%;
  padding: 50px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #090a1a;
  opacity: 90%;
  overflow-y: hidden;
  border-radius: 15px;
}

@media (max-width: 1200px) {
  .gameDonnut-content {
    margin-top: 0px;
    height: auto;
  }

  .sidebar {
    display: none;
  }

  .content {
    width: 100%;
  }
}

/* Mobyle Menu */

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: auto;
  height: auto;
  padding: 10px;
  background-color: #1a1b2c;
  display: flex;
  z-index: 9999;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
}

body {
  font-family: sans-serif;
  margin: 0;
  overflow-y: auto;
}

/* Round information section */
.round-info {
  display: flex;
  flex-direction: column;
}

.round-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.round-number {
  font-size: 15px;
}

.round-time {
  font-size: 20px;
  color: cyan;
  border: solid cyan 1px;
  border-radius: 5px;
  padding: 4px;
}

/* Statistics section */
.stats-container-up {
  display: flex;
  flex-direction: column;
}

.stats-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20%;
}

.stats-item {
  flex: 1;
  flex-direction: column;
}

.stat-value {
  color: white;
  font-size: 1.1rem;
  text-shadow: 0 0 5px #fff;
  margin-bottom: -5px;
}

.stat-label {
  font-size: 10px;
}

/* Desktop Size */

.desktop-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -30px;
}

.desktop-content-current-round {
  margin: 0;
}

.desktop-content-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10%;
  align-items: center;
  width: 40%;
}

.desktop-content-history,
.desktop-content-nav-button {
  border: 1px solid gray;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s;
  font-size: 12px;
}

.desktop-content-nav-button {
  width: 30px;
  text-align: center;
}

.desktop-content-image {
  width: 90%;
}

/* Players  */

.player-container {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  width: 100%;
}

.player-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.player-watching {
  color: greenyellow;
}

.player-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  opacity: 0.8;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.player-item:hover {
  opacity: 1;
  transform: scale(1.03);
}

.player-info {
  display: flex;
  flex: 1;
}

.player-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 5px;
}

.player-details {
  display: flex;
  flex-direction: column;
}

.player-points {
  margin-top: -20px;
  font-size: 12px;
}

.player-stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.player-stat-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-stat-percentage {
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
}

.player-stat-value {
  margin-top: -20px;
  font-size: 12px;
}

.player-stat-bar {
  width: 12px;
  height: 50px;
  margin-left: 10px;
  border-top-right-radius: 80%;
  border-bottom-right-radius: 80%;
}

.player-stat-bar-blue {
  background-color: blue;
}

.player-stat-bar-red {
  background-color: red;
}

.confetti-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 90vw;
  height: 100vh;
  pointer-events: none;
}

@media (max-width: 500px) {
  .confetti-container {
    width: 700px;
    height: auto;
    top: 55%;
    left: 48%;
  }
}

@media (max-width: 400px) {
  .confetti-container {
    width: 550px;
    height: auto;
    top: 50%;
    left: 47%;
  }
}

/*-------------------------------------------------- Deposit PopUp ------------------------------------------------------------- */

/* Para navegadores WebKit (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Estilo adicional para assegurar consistência */
input[type="number"] {
  appearance: none;
}
