* {
  margin: 0;
  padding: 0;
}

.App {
  /* background-color: #111927; */
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: "asideLeft main asideRight";
}

.App > * {
  border-radius: 15px;
  margin: 10px;
  /* background-color: #0d131e; */
  color: white;
  padding: 10px;
}

.confetti-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 90vw;
  height: 100vh;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .confetti-container {
    top: 60%;
    left: 38%;
  }
}

@media (max-width: 500px) {
  .confetti-container {
    width: 700px;
    height: auto;
    top: 55%;
    left: 48%;
  }
}

@media (max-width: 400px) {
  .confetti-container {
    width: 550px;
    height: auto;
    top: 50%;
    left: 47%;
  }
}

.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  rotate: -90deg;
  background-color: #222;
}

.pie-rotation {
  transform-origin: center center; /* Define o centro do gráfico como o ponto de origem da rotação */
  transition: transform 4s cubic-bezier(0.25, 0.1, 0.25, 1); /* Animação de rotação suave */
}
